import React, { useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import { toast } from "react-toastify";

import { styled } from "@mui/system";
import logo from "../../assets/logo.JPG"
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(1),
}));

const FieldContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const FieldTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  color: "#333",
}));

const FieldValue = styled(Typography)(({ theme }) => ({
  color: "#555",
}));

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

const ViewDetail = ({ student, handleBackToStudentList }) => {
  const invoiceRef = useRef();

  const printData = async (invoiceData) => {
    const data = {
      ...invoiceData,
      name: `${student[0].firstName} ${student[0].lastName}`,
      fatherName: invoiceData.fatherName || student[0].fatherName
    };

    const response = await fetch(
      `https://api-2afwy3hsbq-uc.a.run.app/api/user/generate-invoice`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.ok) {
      const file = await response.blob();
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = fileURL;
      a.download = `invoice-${invoiceData.invoiceNumber}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(fileURL);
      toast.success("Invoice generated successfully!");
    } else {
      toast.error("Error generating the invoice, please try again");
    }
  };

  const handleGeneratePdf = () => {
    const options = {
      filename: `${student[0].firstName}_${student[0].lastName}.pdf`, 
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    const element = invoiceRef.current;
    html2pdf().set(options).from(element).save();
  };
  return (
    <>
      <Box mt={2} textAlign={"right"}>
        <Button variant="contained" color="primary" onClick={handleGeneratePdf}>
          Generate PDF
        </Button>
      </Box>
      <Box mt={3} ref={invoiceRef} className="pdf-content">
      <Grid item xs={12}
          sx={{display:'flex', justifyContent:"space-between", alignItems: "center", margin: "0 1rem"}}
          >
           <span>Owner Name: Amar Kumar Pandey</span>
           <span>Shri Ganeshay Namah</span>
           <span>Mobile Number: 9708400495, 9939449414</span>
          </Grid>
        <Typography variant="h2" gutterBottom
          textAlign={"center"}
          textTransform={"capitalize"}>
           P.B. Hostel Boys & Girls 
          </Typography>
          <Typography variant="h4" gutterBottom
          textAlign={"center"}
          >
           Near CV Raman University
          </Typography>
          
          <Typography variant="h4" gutterBottom
          textAlign={"center"}
          >
           Bafapur Banthu, Bhagwanpur (Vaishali)
          </Typography>
          <Typography variant="h4" gutterBottom
          textAlign={"center"}
          >
           <img src={logo} alt="" style={{width: "100px", height: "100px"}}/>
          </Typography>
        <Typography
          variant="h4"
          gutterBottom
          textAlign={"center"}
          textTransform={"capitalize"}
        >
          Student Details
        </Typography>

        {student.map((data, index) => (
          <StyledPaper key={index}>
            <Grid container spacing={2}>
              {[
                {
                  label: "Admission Date",
                  value: formatDate(data.admissionDate),
                },
                { label: "First Name", value: data.firstName },
                { label: "Middle Name", value: data.middleName },
                { label: "Last Name", value: data.lastName },
                { label: "Gender", value: data.gender },
                { label: "Date of Birth", value: formatDate(data.dateOfBirth) },
                { label: "Nationality", value: data.nationality },
                { label: "Mobile Number", value: data.mobileNumber },
                { label: "Aadhar Card", value: data.aadharCard },
                { label: "Pan Card", value: data.pancard },
                { label: "Residential Phone", value: data.residentPhone },
                { label: "Email", value: data.email },
                { label: "Correspondence Address", value: data.address },
                { label: "Pincode", value: data.pincode },
                { label: "Permanent Address", value: data.permanentAddress },
                {
                  label: "Permanent Address Pincode",
                  value: data.permanentAddressPincode,
                },
                { label: "Mother's Name", value: data.motherName },
                {
                  label: "Mother's Mobile Number",
                  value: data.motherMobileNumber,
                },
                { label: "Father's Name", value: data.fatherName },
                {
                  label: "Father's Mobile Number",
                  value: data.fatherMobileNumber,
                },
                { label: "Guardian's Name", value: data.guardianName },
                {
                  label: "Guardian's Mobile Number",
                  value: data.guardianMobile,
                },
                { label: "Guardian's Address", value: data.guardianAddress },
              ].map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <FieldContainer>
                    <FieldTitle variant="body1">{item.label}:</FieldTitle>
                    <FieldValue variant="body1">{item.value}</FieldValue>
                  </FieldContainer>
                </Grid>
              ))}

              <Grid item xs={12} className="page-break"></Grid>
              <Grid item xs={12} >
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: 20 }}
                  textAlign={"center"}
                >
                  Invoices
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {data.invoices.map((invoice, index) => (
                  <Paper
                    key={invoice._id}
                    style={{ padding: 10, marginBottom: 10 }}
                  >
                    <Typography
                      variant="body1"
                      sx={styles.cursorPointer}
                      onClick={() => printData(invoice)}
                    >
                      <strong>Invoice Number:</strong> {invoice.invoiceNumber}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Date:</strong>{" "}
                      {new Date(invoice.date).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Address:</strong> {invoice.address}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Father's Name:</strong> {invoice.fatherName || data.fatherName}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Total Amount:</strong> {invoice.totalAmount}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Paid Amount:</strong> {invoice.paidAmount}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Next Payment Date:</strong>{" "}
                      {new Date(invoice.nextPaymentDate).toLocaleDateString()}
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <strong>Sr. No.</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Description</strong>
                            </TableCell>
                            <TableCell>
                              <strong>Amount</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {invoice.items.map((item, itemIndex) => (
                            <TableRow key={item._id}>
                              <TableCell>{itemIndex + 1}</TableCell>
                              <TableCell>{item.description}</TableCell>
                              <TableCell>{item.amount}</TableCell>
                            </TableRow>
                          ))}
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                              <strong>Subtotal</strong>
                            </TableCell>
                            <TableCell>{invoice.totalAmount}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                              <strong>Paid To Date</strong>
                            </TableCell>
                            <TableCell>{invoice.paidAmount}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                              <strong>Balance Due</strong>
                            </TableCell>
                            <TableCell>
                              {invoice.totalAmount - invoice.paidAmount}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ))}
                {data.invoices.length === 0 && (
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ marginTop: 20 }}
                    textAlign={"center"}
                  >
                    No invoice record present
                  </Typography>
                )}
              </Grid>
            </Grid>
          </StyledPaper>
        ))}
      </Box>
      
      <Box mt={2}>
        <Button variant="contained" onClick={handleBackToStudentList}>
          Back to Student List
        </Button>
      </Box>
    </>
  );
};

const styles = {
  cursorPointer: {
    cursor: "pointer",
  },
};

export default ViewDetail;
